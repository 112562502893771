module.exports = [{
      plugin: require('/home/circleci/dev-blog-v1/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":960},
    },{
      plugin: require('/home/circleci/dev-blog-v1/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/circleci/dev-blog-v1/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/home/circleci/dev-blog-v1/src/cms/index.js"},
    },{
      plugin: require('/home/circleci/dev-blog-v1/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":[""],"pluginConfig":{"head":true}},
    },{
      plugin: require('/home/circleci/dev-blog-v1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"pm11 blog","short_name":"pm11 blog","start_url":"/","background_color":"#FFF","theme_color":"#F7A046","display":"standalone","icon":"static/photo.png"},
    },{
      plugin: require('/home/circleci/dev-blog-v1/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/circleci/dev-blog-v1/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/circleci/dev-blog-v1/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
